import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';
import { Button } from '../../components/atoms';

import './deepconnect.scss';

const deepconnectQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "deepconnect" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
            }
          }
          imageAlt
        }
      }
    }
    allFile(filter: { name: { regex: "/(anleitung|drag|overview)/" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 92, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const DeepconnectCase = () => (
  <StaticQuery
    query={deepconnectQuery}
    render={({ allStagesJson, allFile }) => {
      const stageData = allStagesJson.edges[0]?.node;
      const classImg = getImage(allFile.edges[3]?.node.childImageSharp);
      const meetupImg = getImage(allFile.edges[1]?.node.childImageSharp);

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              gatsbyImageData: stageData?.imageSrc?.childImageSharp?.gatsbyImageData,
              alt: stageData?.imageAlt || 'DeepConnect Image',
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData?.title || 'DeepConnect' }} />}
          >
            <div>
              {stageData?.contentBlocks?.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
            <div className="download__button">
              <Button url="https://deepconnect.deepreturn.com" text="Download" isPrimary />
            </div>
          </Stage>

          <CaseBlock
            title="Zeitersparnis durch Logik"
            subtitle="Dateien automatisiert manipulieren"
            graphic={classImg && <GatsbyImage image={classImg} alt="DeepConnect Automation" />}
          >
            <p>
              DeepConnect ist immer dann hilfreich, wenn Sie Formatierungen an einer CSV oder XML Datei vornehmen wollen.
            </p>
          </CaseBlock>

          <CaseBlock
            title="Hier zum Download"
            subtitle="Erfahren Sie mehr über Preise und unser Angebot"
            graphic={meetupImg && <GatsbyImage image={meetupImg} alt="Preise" />}
          >
            <p>
              DeepConnect hilft Ihnen, strukturierte Daten automatisiert zu formatieren. Auch für Nutzer ohne
              Programmiererfahrung!
            </p>
          </CaseBlock>

          <div className="container">
            <Facts title="Technologie">
              <ul>
                <li>Einfache Bedienung durch Drag&Drop</li>
                <li>Die Ordnerüberwachung ermöglicht automatisierten Ablauf</li>
                <li>Zeitersparnis durch einmalige Umsetzung</li>
                <li>Dateien werden lokal konvertiert</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default DeepconnectCase;
